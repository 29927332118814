// ** Redux Imports
import { collection, getDocs } from "firebase/firestore"
import { db } from "@src/firebase"
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appDGs/getAllData', async () => {

  const loadDGsFirebase = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "dgs"))
      const listaDGs = []
      querySnapshot.forEach(async (doc) => {
        const item = await doc.data()
        listaDGs.push({
          id: doc.id,
          ...item
        })
      })
      return listaDGs
    } catch (error) {
      console.log(error.message)
      return null
    }
  }

  const dgs = await loadDGsFirebase()
  console.log({ dgs })
  return dgs
})

export const getData = createAsyncThunk('appDGs/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appDGs/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appDGs/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appDGs/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appDGsSlice = createSlice({
  name: 'appDGs',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appDGsSlice.reducer