
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCMJNuss6bivpDAW1HnKzgNciXu4I2b4bM",
  authDomain: "dunamis-679b0.firebaseapp.com",
  projectId: "dunamis-679b0",
  storageBucket: "dunamis-679b0.appspot.com",
  messagingSenderId: "337909613387",
  appId: "1:337909613387:web:e254c39c27ce2ee1441ece"
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)

export {
  auth,
  db,
  storage
}