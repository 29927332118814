// ** Redux Imports
import { collection, getDocs } from "firebase/firestore"
import { db } from "@src/firebase"
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appMinisterios/getAllData', async () => {

  const loadMinisteriosFirebase = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "ministerios"))
      const listaMinisterios = []
      querySnapshot.forEach(async (doc) => {
        const item = await doc.data()
        listaMinisterios.push({
          id: doc.id,
          ...item
        })
      })
      return listaMinisterios
    } catch (error) {
      console.log(error.message)
      return null
    }
  }

  const ministerios = await loadMinisteriosFirebase()
  console.log({ ministerios })
  return ministerios
})

export const getData = createAsyncThunk('appMinisterios/getData', async params => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appMinisterios/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appMinisterios/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appMinisterios/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appMinisteriosSlice = createSlice({
  name: 'appMinisterios',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appMinisteriosSlice.reducer


// import { collection, getDocs, doc, getDoc } from "firebase/firestore"
// import { db } from "@src/firebase"
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// const initialState = {
//   ministerios: null
// }

// const loadMinisteriosFirebase = async () => {
//   try {
//     const querySnapshot = await getDocs(collection(db, "membros"))
//     const listaMembros = []
//     querySnapshot.forEach(async (doc) => {
//       const item = await doc.data()
//       listaMembros.push({
//         id: doc.id,
//         ...item
//       })
//     })
//     return listaMembros
//   } catch (error) {
//     console.log(error.message)
//     return null
//   }
// }

// // ** Fetch Mails
// export const fetchMinisterios = createAsyncThunk('appMinisterios/fetchMinisterios', async (params) => {
//   const ministerios = await loadMinisteriosFirebase()
//   console.log({ ministerios });
//   return { ...ministerios, filter: params }
// })

// const ministeriosSlice = createSlice({
//   name: 'ministerios',
//   initialState,
//   reducers: {},
//   extraReducers: builder => {

//     // ** update the state.mails when the fetchMinisterios action is fulfilled.
//     builder.addCase(fetchMinisterios.fulfilled, (state, action) => {
//       state.ministerios = action.payload.ministerios
//     })      
//   }
// })

// export default ministeriosSlice.reducer